import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, useToken } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { Element } from '../../modules/presentation/components/Element';
import { Loading } from '../../modules/commons/components/Loading';
import { useSlide } from '../../modules/presentation/hooks/useSlide.ts';
import { hexToRGBA } from '../../util/ColorUtils.ts';
import { AlertBox } from '../../modules/commons/components/AlertBox';
import { SliderControlsProvider } from '../../modules/presentation/components/SliderControlsProvider';

export function usePreviewPageInfo() {
	const location = useLocation();
	const isPreviewPage = location.pathname.includes('/preview');
	const shouldAutoFocus = !isPreviewPage;
	return {
		isPreviewPage,
		shouldAutoFocus,
	};
}

export const PreviewPage: React.FC = () => {
	const { presentationId, currentSlide } = useParams();
	const [background] = useToken('colors', ['background']);
	const { data: actualSlide, isPending, isError } = useSlide(presentationId!, currentSlide!);

	const handleOnInteractionEnd = () => {
		// Interaction end handler logic
	};

	if (isPending) {
		return <Loading />;
	}

	if (isError) {
		return <AlertBox status='error' title={`Error fetching Slide: ${currentSlide}`} />;
	}

	if (isEmpty(actualSlide)) {
		return <AlertBox status='error' title='Slide not found' />;
	}

	return (
		<Box
			id='preview-wrapper'
			h='100svh'
			backgroundRepeat='no-repeat'
			backgroundSize='cover'
			backgroundPosition='center'
			backgroundImage={
				!isEmpty(actualSlide.imageBackgroundUrl)
					? `linear-gradient(${hexToRGBA(background, 0.5)}, ${hexToRGBA(background, 0.5)}), url(${actualSlide.imageBackgroundUrl})`
					: undefined
			}
		>
			<SliderControlsProvider
				currentSlideId={actualSlide.id}
				presentation={{
					id: presentationId!,
					description: '',
					slides: [actualSlide],
					hideControls: true,
					hideProgressBar: true,
				}}
			>
				<Element presentationId={presentationId!} slide={actualSlide} onInteractionEnd={handleOnInteractionEnd} />
			</SliderControlsProvider>
		</Box>
	);
};

export default PreviewPage;
