import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Flex, Text, VStack } from '@chakra-ui/react';
import { MultipleChoiceConfigurationT } from '../../model/Interaction.ts';
import { InteractionWrapper } from '../InteractionWrapper';
import { InteractionContainer } from '../InteractionContainer';
import { saveMultipleChoiceEntry } from '../../service/EntryService.ts';
import { RadioCard } from './_/RadioCard';
import { CheckboxCard } from './_/CheckboxCard';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { validateRequired } from '../../validation/InteractionValidation.ts';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import isEmpty from 'lodash/isEmpty';

interface FormValueType {
	multipleEntry: string[];
	singleEntry: string;
}

export const MultipleChoiceInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, onInteractionEnd }) => {
	const config = interaction.configuration as MultipleChoiceConfigurationT;
	const alreadySubmitted = hasSubmission(interaction.id);
	const {
		handleSubmit,
		register,
		formState: { isSubmitting, errors },
		watch,
		reset,
	} = useForm<FormValueType>({
		defaultValues: {
			multipleEntry: [],
			singleEntry: '',
		},
	});

	/**
	 * Reset the form if the configuration changes to or from multiple answers
	 * Fix the bug
	 * - Start as multipleAnswer === true
	 * - Select a choice
	 * - Change multipleAnswer === false
	 * - This interaction breaks
	 */
	useEffect(() => {
		reset();
	}, [config.multipleAnswer]);

	const onSubmit = async (data: FormValueType) => {
		const entries: string[] = data.singleEntry ? [data.singleEntry] : data.multipleEntry;
		if (!isEmpty(entries)) {
			await saveMultipleChoiceEntry(presentationId, interaction.id, entries);
			recordSubmission(interaction.id);
			onInteractionEnd();
		} else if (alreadySubmitted || !interaction.required) {
			onInteractionEnd();
		}
	};
	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting}>
			<InteractionContainer>
				{errors.singleEntry || errors.multipleEntry ? (
					<Flex justifyContent='center' mb={4}>
						<Text color='red'>Pick an option</Text>
					</Flex>
				) : null}
				<VStack gap={4}>
					{config.multipleAnswer ? (
						<>
							{config.options.map((option, index) => (
								<CheckboxCard
									key={index}
									option={option}
									{...register('multipleEntry', {
										validate: {
											required: (value) => {
												return interaction.required && !alreadySubmitted ? validateRequired(value, 'Select an option') : undefined;
											},
										},
									})}
								/>
							))}
						</>
					) : (
						<>
							{config.options.map((option, index) => (
								<RadioCard
									key={index}
									option={option}
									currentOption={watch('singleEntry')}
									{...register('singleEntry', {
										validate: {
											required: (value) => {
												return interaction.required && !alreadySubmitted ? validateRequired(value, 'Select an option') : undefined;
											},
										},
									})}
								/>
							))}
						</>
					)}
				</VStack>
			</InteractionContainer>
		</InteractionWrapper>
	);
};
