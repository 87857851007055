import isEmpty from 'lodash/isEmpty';

export function validateRequired(value: string | string[], message: string = 'This field is required'): string | undefined {
	return isEmpty(value) ? message : undefined;
}

export function validateMinLength(value: string, length: number = 3, message: string = 'Too short'): string | undefined {
	return !isEmpty(value) && value.length < length ? message : undefined;
}

export function validateMaxLength(value: string, length: number = 2400, message: string = 'Too long'): string | undefined {
	return !isEmpty(value) && value.length > length ? message : undefined;
}
