import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Flex, Icon, IconButton, InputGroup, InputRightElement, Text, Textarea } from '@chakra-ui/react';
import { MdAdd, MdClose } from 'react-icons/md';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import trim from 'lodash/trim';
import { OpenEndedInteractionConfigurationT } from '../../model/Interaction.ts';
import { saveOpenEndedLongEntry } from '../../service/EntryService.ts';
import { QUESTION_INTERACTION_LONG_ANSWER_MAX_LENGTH } from '../../../../util/Constants.ts';
import { InteractionWrapper } from '../InteractionWrapper';
import { InteractionContainer } from '../InteractionContainer';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { validateMaxLength, validateMinLength, validateRequired } from '../../validation/InteractionValidation.ts';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';

interface EntryT {
	value: string;
}

interface FormValues {
	entries: EntryT[];
}

export const OpenEndedLongInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, onInteractionEnd }) => {
	const config = interaction.configuration as OpenEndedInteractionConfigurationT;
	const alreadySubmitted = hasSubmission(interaction.id);
	const {
		register,
		handleSubmit,
		control,
		formState: { isSubmitting, errors },
		watch,
	} = useForm<FormValues>({
		defaultValues: {
			entries: [],
		},
	});

	const { fields, append, remove } = useFieldArray({
		name: 'entries',
		control,
		rules: {
			required: interaction.required,
			maxLength: config.maximumAnswers,
		},
	});

	useEffect(() => {
		if (isEmpty(fields)) {
			append({ value: '' }, { shouldFocus: true });
		}
	}, [fields, append]);

	const canAdd = fields.length < config.maximumAnswers;
	const canRemove = fields.length > 1;

	const onSubmit = async (data: FormValues) => {
		const entries = data.entries.map((x: EntryT) => x.value);
		const filtered = filter(map(entries, trim), size) as string[];
		if (!isEmpty(filtered)) {
			await saveOpenEndedLongEntry(presentationId, interaction.id, filtered);
			recordSubmission(interaction.id);
			onInteractionEnd();
		} else if (alreadySubmitted || !interaction.required) {
			onInteractionEnd();
		}
	};

	const handleAdd = () => {
		if (canAdd) {
			append({ value: '' }, { shouldFocus: false });
		}
	};

	const handleRemove = (index: number) => () => {
		if (canRemove) {
			remove(index);
		}
	};

	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting}>
			<InteractionContainer>
				<Flex flexDirection='column' gap={2} p={2}>
					{!isEmpty(errors.entries) ? (
						<Text color='red' textAlign='center'>
							{errors.entries[0]?.value?.message}
						</Text>
					) : undefined}
					{fields.map((field, index) => (
						<InputGroup size='lg' key={field.id} mb={1} flexDirection='column'>
							<Textarea
								rows={8}
								minLength={5}
								maxLength={QUESTION_INTERACTION_LONG_ANSWER_MAX_LENGTH}
								required={interaction.required}
								id={field.id}
								size='lg'
								{...register(`entries.${index}.value` as const, {
									onChange: () => {
										const emptyField = watch('entries').filter((value) => value.value === '').length > 0;
										if (!emptyField && canAdd) {
											handleAdd();
										}
									},
									validate: {
										required: (value) => {
											return interaction.required && !alreadySubmitted ? validateRequired(value, 'Answer required') : undefined;
										},
										minLength: (value) => {
											return validateMinLength(value);
										},
										maxLength: (value) => {
											return validateMaxLength(value, 500);
										},
									},
								})}
							/>
							{index !== 0 ? (
								<InputRightElement width='0.5rem' px={6} onClick={handleRemove(index)}>
									<IconButton rounded='50' aria-label='Delete' size='md' fontWeight='bold' icon={<Icon color='brand' as={MdClose} />} />
								</InputRightElement>
							) : null}
						</InputGroup>
					))}
				</Flex>
				{canAdd ? (
					<Flex px={3} py={0} my={0} justifyContent='flex-end'>
						<IconButton mt={4} fontWeight='bold' rounded='50' bg='brand' aria-label='Add' onClick={handleAdd} size='lg' icon={<Icon as={MdAdd} />} />
					</Flex>
				) : null}
			</InteractionContainer>
		</InteractionWrapper>
	);
};
