import React from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { FaLink, FaUnsplash } from 'react-icons/fa';
import { UnsplashImagePicker } from '../../../unsplash/components/UnsplashImagePicker';
import { UnsplashPhoto } from '../../../unsplash/api/UnsplashApi.ts';
import { UrlImagePicker } from './_/UrlImagePicker';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onChange: (selectedImageUrl: string) => void;
}

export const ImagePicker: React.FC<Props> = ({ isOpen, onClose, onChange }) => {
	const handleOnUnsplashImage = (photo: UnsplashPhoto) => {
		onChange(photo.urls.regular);
		onClose();
	};

	const handleOnUrlImagePicker = (imageUrl: string) => {
		onChange(imageUrl);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='full'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader p={8}>Search image</ModalHeader>
				<ModalCloseButton />
				<ModalBody px={8}>
					<Tabs variant='line' my={4}>
						<TabList>
							<Tab fontSize={20}>
								<FaUnsplash />
								<Text pl={2}>Unsplash Photo</Text>
							</Tab>
							<Tab fontSize={20}>
								<FaLink />
								<Text pl={2}>Self-hosted image</Text>
							</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<UnsplashImagePicker onChange={handleOnUnsplashImage} />
							</TabPanel>
							<TabPanel>
								<UrlImagePicker onChange={handleOnUrlImagePicker} />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

ImagePicker.defaultProps = {};
