export const translations = {
	en: {
		translation: {
			Submit: 'Submit',
			'It seems there are no answers to this question yet.': 'It seems there are no answers to this question yet.',
			'Add a title to your drawing': 'Add a title to your drawing',
			'Add a description': 'Add a description',
			'Select image': 'Select image',
			'Enter a link': 'Enter a link',
		},
	},
	es: {
		translation: {
			Submit: 'Enviar',
			'It seems there are no answers to this question yet.': 'Parece que no hay respuestas a esta pregunta todavía.',
			'Add a title to your drawing': 'Añade título a tu dibujo',
			'Add a description': 'Añade una descripción',
			'Select image': 'Selecciona una imágen',
			'Enter a link': 'Ingresa un link',
		},
	},
} as const;
