import React, { useEffect, useRef, useState } from 'react';
import { Flex, Heading, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { motion } from 'framer-motion';
import { useRotatingWordsData } from '../../hooks/useRotatingWordsData.ts';
import { Loading } from '../../../commons/components/Loading';
import { insertNewlineEveryAmountWords } from '../../../../util/StringUtil.ts';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import { translate } from '../../../../translate';

const TRANSITION_TIME_MS = 3000;

export const RotatingWordsVisualization: React.FC<VisualizationProps> = ({ presentationId, visualization }) => {
	// const conf = visualization.configuration as RotatingWordsConfiguration;
	const { data, isLoading } = useRotatingWordsData(presentationId, visualization.id);
	const [currentWord, setCurrentWord] = useState<string>('');
	const currentIndexRef = useRef(0);
	const hasData = !isLoading && !isEmpty(data);
	const parts = visualization.title.split('{{word}}');

	const getRandomWord = () => {
		if (isLoading || !data || data.length === 0) return '';
		const word = data[currentIndexRef.current % data.length]; // Get word sequentially using useRef
		currentIndexRef.current = (currentIndexRef.current + 1) % data.length; // Increment index for next call without causing re-renders
		return word;
	};

	// Initialize currentWord with the first word from data
	useEffect(() => {
		setCurrentWord(getRandomWord());
	}, [data]); // Empty dependency array to run only once after initial render

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentWord(getRandomWord());
		}, TRANSITION_TIME_MS);

		return () => clearInterval(interval);
	}, [data]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Flex flexDirection='column' h='100svh' mx={14} justifyContent='center' alignItems='center'>
			{hasData ? (
				<>
					{parts.map((part, index) => (
						<>
							<Flex key={index}>
								{index > 0 && (
									<motion.div key={currentWord} initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5, ease: 'easeOut' }}>
										<Text fontSize='3xl' borderRadius={10} py={1} px={4} mt={2} bg='brand' color='white' textAlign='center'>
											{insertNewlineEveryAmountWords(currentWord)}
										</Text>
									</motion.div>
								)}
							</Flex>
							<Flex>
								<Text fontSize='4xl'>{part}</Text>
							</Flex>
						</>
					))}
				</>
			) : (
				<Heading fontSize='2xl' mt={0}>
					{translate('It seems there are no answers to this question yet.')}
				</Heading>
			)}
		</Flex>
	);
};

RotatingWordsVisualization.defaultProps = {};
