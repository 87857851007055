import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme';
import { checkboxTheme } from './checkbox.ts';

const headingTheme: ComponentStyleConfig = {
	baseStyle: {
		my: '12',
		color: 'textColor',
	},
	defaultProps: {},
};

const buttonTheme: ComponentStyleConfig = {
	baseStyle: {
		bgColor: 'brand',
		h: '3.75rem',
		minW: '3.75rem',
		fontSize: 'xl',
		fontWeight: 'bold',
		textTransform: 'uppercase',
		px: 7,
		my: 8,
		color: 'white',
		borderRadius: 'full',
	},
	defaultProps: {
		colorScheme: '',
		size: 'lg',
	},
};

// const tableTheme: ComponentStyleConfig = {
// 	variants: {
// 		simple: {
// 			th: {
// 				background: 'white',
// 				borderTop: 'none',
// 			},
// 			tr: {
// 				_hover: { cursor: 'pointer', bg: 'hover' },
// 			},
// 		},
// 	},
// 	defaultProps: {
// 		variant: 'simple',
// 	},
// };

const menuTheme: ComponentStyleConfig = {
	variants: {
		simple: {
			item: {
				_hover: {
					bg: 'hover',
				},
				_active: {
					bg: 'hover',
				},
				_focus: {
					bg: 'hover',
				},
			},
		},
	},
};

const radioCardTheme: ComponentStyleConfig = {
	baseStyle: {
		minW: '10rem',
		bg: 'inputColor',
		py: '3',
		boxShadow: 'md',
		background: 'light',
		borderWidth: '2px',
		borderStyle: 'solid',
		borderColor: 'transparent',
		borderRadius: '3xl',
		_hover: {
			bg: 'highlight',
			borderColor: 'transparent',
		},
		_checked: {
			boxShadow: 'none',
			borderWidth: '2px',
			borderStyle: 'solid',
			borderColor: 'brand',
			bg: 'highlight',
		},
	},
	defaultProps: {},
};

const inputTheme: ComponentStyleConfig = {
	variants: {
		outline: {
			field: {
				h: 'inputHeight',
				w: 'inputWidth',
				bg: 'inputColor',
				color: 'textColor',
				border: 'borderStroke',
				borderColor: 'brand',
				borderRadius: '3xl',
				boxShadow: 'md',
				_hover: {
					boxShadow: 'lg',
					border: 'borderStroke',
					borderColor: 'brand',
				},
				_focus: {
					boxShadow: 'lg',
					border: 'borderStroke',
					borderColor: 'brand',
				},
				_focusVisible: {
					boxShadow: 'none',
					border: 'borderStroke',
					borderColor: 'brand',
				},
				_placeholder: {
					color: 'textColor',
				},
			},
		},
	},
};

const numberInputTheme: ComponentStyleConfig = {
	variants: {
		outline: {
			field: {
				h: 'inputHeight',
				w: 'inputWidth',
				bg: 'inputColor',
				color: 'textColor',
				border: 'borderStroke',
				borderColor: 'brand',
				borderRadius: '2xl',
				boxShadow: 'md',
				_hover: {
					boxShadow: 'lg',
					border: 'borderStroke',
					borderColor: 'brand',
				},
				_focus: {
					boxShadow: 'lg',
					border: 'borderStroke',
					borderColor: 'brand',
				},
				_focusVisible: {
					boxShadow: 'none',
					border: 'borderStroke',
					borderColor: 'brand',
				},
				_placeholder: {
					color: 'textColor',
				},
			},
		},
	},
};

const textTheme: ComponentStyleConfig = {
	variants: {
		outline: {
			bg: 'inputColor',
			color: 'textColor',
			border: 'borderStroke',
			borderColor: 'brand',
			_hover: {
				boxShadow: 'none',
				color: 'textColor',
				border: 'borderStroke',
				borderColor: 'brand',
			},
			_focus: {
				boxShadow: 'none',
				color: 'textColor',
				border: 'borderStroke',
				borderColor: 'brand',
			},
			_focusVisible: {
				boxShadow: 'none',
				color: 'textColor',
				border: 'borderStroke',
				borderColor: 'brand',
			},
			_placeholder: {
				color: 'textColor',
			},
		},
	},
};

const textAreaTheme: ComponentStyleConfig = {
	variants: {
		outline: {
			bg: 'inputColor',
			color: 'textColor',
			border: 'borderStroke',
			borderColor: 'brand',
			boxShadow: 'md',
			borderRadius: '3xl',
			_hover: {
				boxShadow: 'lg',
				color: 'textColor',
				border: 'borderStroke',
				borderColor: 'brand',
			},
			_focus: {
				boxShadow: 'lg',
				color: 'textColor',
				border: 'borderStroke',
				borderColor: 'brand',
			},
			_focusVisible: {
				boxShadow: 'none',
				border: 'borderStroke',
				borderColor: 'brand',
			},
			_placeholder: {
				color: 'textColor',
			},
		},
	},
};

// const selectTheme: ComponentStyleConfig = {
// 	variants: {
// 		outline: {
// 			field: {
// 				h: 'inputHeight',
// 				w: 'inputWidth',
// 				bg: 'inputColor',
// 				color: 'textColor',
// 				border: 'borderStroke',
// 				borderColor: 'brand',
// 				_hover: {
// 					// color: 'textColor',
// 					// bg: 'inputColor',
// 					border: 'borderStroke',
// 					// borderColor: 'brand',
// 				},
// 				_focus: {
// 					// color: 'textColor',
// 					// bg: 'inputClor',
// 					boxShadow: '',
// 					// borderColor: 'brand',
// 				},
// 				_expanded: {
// 					boxShadow: '',
// 				},
// 				// _placeholder: {
// 				// 	color: 'textColor',
// 				// 	bg: 'inputColor',
// 				// 	border: 'borderStroke',
// 				// 	borderColor: 'brand',
// 				// },
// 			},
// 		},
// 	},
// };

const cardTheme = {
	baseStyle: {
		container: {
			borderRadius: '3xl',
		},
	},
};

// // These are the default breakpoints
// const breakpoints = {
//   base: "0em", // 0px
//   sm: "30em", // ~480px. em is a relative unit and is dependant on the font-size.
//   md: "48em", // ~768px
//   lg: "62em", // ~992px
//   xl: "80em", // ~1280px
//   "2xl": "96em", // ~1536px
// };

// // Internally, we transform to this
// const breakpoints = ["0em", "30em", "48em", "62em", "80em", "96em"];

const customTheme = {
	styles: {
		global: {
			html: {
				// '*::-webkit-scrollbar': {
				// 	width: '8px',
				// 	height: '8px',
				// },
				// '*::-webkit-scrollbar-track': {
				// 	background: 'transparent',
				// },
				// '*::-webkit-scrollbar-thumb': {
				// 	background: 'var(--chakra-colors-brand)',
				// 	borderRadius: '10px',
				// 	border: '2px solid transparent',
				// },
				// '*::-webkit-scrollbar-thumb:hover': {
				// 	background: 'var(--chakra-colors-brand)',
				// },
				'*': {
					scrollbarWidth: 'thin',
					scrollbarColor: 'var(--chakra-colors-brand) transparent',
				},
			},
			body: {
				bg: 'background',
				color: 'textColor',
			},
			// styles for the `a`
			a: {
				color: 'brand',
				_hover: {
					textDecoration: 'underline',
				},
			},
		},
	},

	borders: {
		borderStroke: '2px solid',
	},

	sizes: {
		inputHeight: '3rem',
		inputWidth: '100%',
	},

	colors: {
		brand: '#000000',
		highlight: '#EDEDED',
		inputColor: '#ffffff',
		textColor: '#000000',
		background: '#ffffff',
		greenSuccess: '#748F4F', //For some reason 'success' has a bug when using `const [successColor] = useToken('colors', ['success'])`
		light: '#F8F9FA',
		colorScheme: {
			50: '#BD5D58',
			100: '#DA9A5C',
			200: '#E2C265',
			300: '#9C9E4C',
			400: '#748F4F',
			500: '#4B7F52',
			600: '#338B81',
			700: '#1B96B0',
			800: '#6B97AB',
			900: '#897A91',
		},
		gray: {
			50: '#E3F2FD',
			100: '#F3F5F7',
			200: '#E6EAEF',
			300: '#CED6DF',
			400: '#B5C1CF',
			500: '#9CACBF',
			600: '#8497AE',
			700: '#6B829E',
			800: '#596E88',
			900: '#498267',
		},
		// colorScheme: {
		// 	50: '#0F5EA3',
		// 	100: '#0A4170',
		// 	200: '#072B4B',
		// 	300: '#0B3044',
		// 	400: '#0F343D',
		// 	500: '#16403D',
		// 	600: '#1D4C3D',
		// 	700: '#24573C',
		// 	800: '#419F6D',
		// 	900: '#71C598',
		// },
	},

	components: {
		Button: buttonTheme,
		Card: cardTheme,
		Checkbox: checkboxTheme,
		// CustomSelect: selectborderRadius: '3xl',Theme,
		Heading: headingTheme,
		Input: inputTheme,
		NumberInput: numberInputTheme,
		Menu: menuTheme,
		RadioCard: radioCardTheme,
		// Switch: switchTheme,
		// Table: tableTheme,
		// Tag: tagTheme,
		Text: textTheme,
		Textarea: textAreaTheme,
	},
	config: {
		initialColorMode: 'light',
		useSystemColorMode: false,
	},
	fonts: {
		heading: 'greycliff-cf',
		body: 'greycliff-cf',
		armCharts: 'greycliff-cf',
		comics: 'cc-mighty-mouth-variable',
	},
};

const extended = extendTheme(proTheme, { ...customTheme });
export default extended;
