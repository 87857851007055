import React from 'react';
import { Title } from '../../../commons/components/Title';
import { Box, Button, Flex } from '@chakra-ui/react';
import { InteractionT } from '../../model/Interaction.ts';
import { SlideArrows } from '../../../presentation/components/SlideArrows';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { translate } from '../../../../translate';
import isEmpty from 'lodash/isEmpty';

interface Props {
	interaction: InteractionT;
	isSubmitting: boolean;
	onSubmit: (e?: React.BaseSyntheticEvent<object, unknown, unknown>) => Promise<void>;
	children: React.ReactNode;
}

const HONEY_POT_FIELD_NAME = 'countryReference';

export const InteractionWrapper: React.FC<Props> = ({ interaction, isSubmitting, onSubmit, children }) => {
	const handleOnSubmit = (e?: React.BaseSyntheticEvent<object, unknown, unknown>) => {
		const honeyPotValue = document.getElementById(HONEY_POT_FIELD_NAME) as HTMLInputElement | null;
		if (isEmpty(honeyPotValue?.value)) {
			onSubmit(e);
		}
	};

	return (
		<>
			<SlideArrows onGoForward={handleOnSubmit} />
			<Flex as='section' h='100%' overflowY='auto'>
				<Box as='form' id={interaction.id} m='auto' /*onSubmit={onSubmit}*/ w={{ base: '90%', sm: '90%', md: '90%', xl: '80%' }} padding={4}>
					<Title title={interaction.title} description={interaction.description} />
					<input id={HONEY_POT_FIELD_NAME} name={HONEY_POT_FIELD_NAME} type='text' style={{ display: 'none' }} />
					{children}
					<Flex justifyContent='center' mt={4}>
						<Button rightIcon={<MdOutlineArrowForwardIos />} isLoading={isSubmitting} /*type='submit'*/ variant='submit' onClick={handleOnSubmit}>
							{translate('Submit')}
						</Button>
					</Flex>
				</Box>
			</Flex>
		</>
	);
};

InteractionWrapper.defaultProps = {};
