export interface StaticContentT {
	id: string;
	title: string;
	description: string;
	content: string;
	type: StaticContentTypeT;
}

export enum StaticContentTypeT {
	HTML = 'HTML',
}
