import { Flex, Heading, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useSliderControls } from '../../../presentation/components/SliderControlsProvider/SliderControlsProvider.tsx';

interface Props {
	title: string;
	description?: string;
}

export const Title: React.FC<Props> = ({ title, description }) => {
	const { currentSlide } = useSliderControls();
	if (currentSlide?.hideTitle) {
		return null;
	}
	return (
		<Flex flexDirection='column' id='title-subtitle' my={2} backdropFilter='blur(5px)' borderRadius='3xl' padding={4}>
			<Heading size={['xs', 'sm']} textAlign='center' wordBreak='break-word' my={2}>
				{title}
			</Heading>
			{!isEmpty(description) ? (
				<Text fontSize={['lg', '2xl']} mb={4} textAlign='center' fontWeight='bold' wordBreak='break-word'>
					{description}
				</Text>
			) : null}
		</Flex>
	);
};

Title.defaultProps = {};
