import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useQuestioryTheme } from '../../hooks/useQuestioryTheme.ts';
import theme from '../../theme.ts';
import { Loading } from '../../../modules/commons/components/Loading';

interface Props {
	children?: React.ReactNode;
}

export const QuestioryThemeProvider: React.FC<Props> = ({ children }) => {
	const { presentationId } = useParams();
	const { isPending, data, error } = useQuestioryTheme(presentationId);
	let currentTheme = theme;

	if (isPending) {
		return <Loading />;
	}

	if (error) {
		console.error(`Error fetching theme for presentation ${presentationId}`);
	}

	if (data) {
		// console.log('User defined theme', data);
		const customTheme = {
			colors: {
				...data.colors,
				greenSuccess: data.colors.success,
				colorScheme: {
					50: data.colors.colorScheme.color50,
					100: data.colors.colorScheme.color100,
					200: data.colors.colorScheme.color200,
					300: data.colors.colorScheme.color300,
					400: data.colors.colorScheme.color400,
					500: data.colors.colorScheme.color500,
					600: data.colors.colorScheme.color600,
					700: data.colors.colorScheme.color700,
					800: data.colors.colorScheme.color800,
					900: data.colors.colorScheme.color900,
				},
			},
		};
		currentTheme = extendTheme(theme, { ...customTheme });
	}

	// console.log('theme', currentTheme);
	return <ChakraProvider theme={currentTheme}>{children}</ChakraProvider>;
};
