import React, { useEffect, useState } from 'react';
import { useToken } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { PictogramConfiguration } from '../../model/Visualization.ts';
import { useGaugeData } from '../../hooks/useGaugeData.ts';
import { extractSVGPathFromUrl } from '../../../../util/SvgUtils.ts';
import { am5 } from '../../../commons/armcharts/AmChartsSetup.ts';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import { VisualizationChartWrapper } from '../VisualizationChartWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';

export const PictogramVisualization: React.FC<VisualizationProps> = ({ visualization, presentationId }) => {
	const conf = visualization.configuration as PictogramConfiguration;
	const [brand] = useToken('colors', ['brand']);
	const { data, isLoading } = useGaugeData(presentationId, visualization.id);
	const [icon, setIcon] = useState('');

	useEffect(() => {
		async function getIconFromUrl() {
			const svgPath = await extractSVGPathFromUrl(conf.icon);
			setIcon(svgPath);
		}

		if (isEmpty(icon)) {
			getIconFromUrl();
		}

		const root = am5.Root.new('chartdiv');

		if (root && !isEmpty(data) && !isEmpty(icon)) {
			const gaugeData = !isEmpty(data)
				? data.entries.map((d) => {
						const color = !isEmpty(d.color) ? d.color : brand;
						return {
							category: d.key,
							value: d.entry,
							full: d.maxValue,
							sliceSettings: {
								fill: am5.color(color),
								stroke: am5.color(color),
							},
						};
					})
				: [];

			//https://www.amcharts.com/docs/v5/concepts/responsive/
			const responsiveTheme = am5themes_Responsive.new(root);
			responsiveTheme.addRule({
				name: 'PictorialStackedSeries',
				relevant: function (width) {
					return width < am5themes_Responsive.XL;
				},
				settings: {
					width: am5.percent(35),
					height: am5.percent(35),
				},
			});

			root.setThemes([am5themes_Animated.new(root), responsiveTheme]);

			// Create chart
			const chart = root.container.children.push(
				am5percent.SlicedChart.new(root, {
					layout: root.verticalLayout,
				})
			);

			// Create series
			const series = chart.series.push(
				am5percent.PictorialStackedSeries.new(root, {
					alignLabels: true,
					orientation: 'vertical',
					valueField: 'value',
					categoryField: 'category',
					svgPath: icon,
				})
			);

			series.slices.template.setAll({
				templateField: 'sliceSettings',
			});

			// Add legend
			// const legend = chart.children.push(
			// 	am5.Legend.new(root, {
			// 		y: am5.percent(100),
			// 		centerY: am5.percent(100),
			// 		marginTop: 15,
			// 	})
			// );

			series.data.setAll(gaugeData);
			// legend.data.setAll(series.dataItems);
		}

		return () => {
			root.dispose();
		};
	}, [data, icon, brand, conf.icon]);

	return <VisualizationChartWrapper title={visualization.title} description={visualization.description} isLoading={isLoading} isNoData={isEmpty(data)} />;
};
