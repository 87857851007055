import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Navigate } from 'react-router-dom';
import { Box, Image, Link, Progress, useToken } from '@chakra-ui/react';
import { hexToRGBA } from '../../../../util/ColorUtils.ts';
import { Element } from '../Element';
import { useSliderControls } from '../SliderControlsProvider/SliderControlsProvider.tsx';

interface Props {}

export const Slide: React.FC<Props> = () => {
	const [background] = useToken('colors', ['background']);
	const slider = useSliderControls();

	useEffect(() => {
		//@ts-expect-error: problem with the type for current
		slider.refContainer?.current?.focus();
	}, [slider]);

	if (isEmpty(slider.currentSlide?.id)) {
		return <Navigate to={`/presentation/${slider.presentation.id}`} replace />;
	}

	if (slider.currentSlide && !isEmpty(slider.currentSlide?.id)) {
		return (
			<Box
				id='background'
				h='100svh'
				backgroundRepeat='no-repeat'
				backgroundSize='cover'
				backgroundPosition='center'
				backgroundImage={
					!isEmpty(slider.currentSlide.imageBackgroundUrl)
						? `linear-gradient(${hexToRGBA(background, 0.5)}, ${hexToRGBA(background, 0.5)}), url(${slider.currentSlide.imageBackgroundUrl})`
						: undefined
				}
			>
				{/* Slide Content */}
				<Element presentationId={slider.presentation.id} slide={slider.currentSlide} onInteractionEnd={slider.goForward} />
				{/* Progress Bar */}
				{!slider.presentation.hideProgressBar ? (
					<Progress
						size='sm'
						w={`${(slider.currentSlideIndex / slider.totalSlides) * 100}%`}
						bg='brand'
						position='fixed'
						h={2}
						top={0}
						left={0}
						style={{ transition: 'width 0.5s ease-out' }}
						borderLeftRadius='none'
						borderRightRadius={slider.currentSlideIndex !== slider.totalSlides ? 'full' : 'none'}
					/>
				) : null}
				<Link href='https://questiory.com?utm_source=player&utm_medium=referral' isExternal>
					<Image
						src='/questiory_logo_inverted.png'
						position='fixed'
						bottom='20px'
						right='20px' // Adjust for right positioning
						boxSize={{ base: '2rem', lg: '3rem' }} // Adjust size as needed
						borderRadius='full'
						alt='Logo'
					/>
				</Link>
			</Box>
		);
	}
};
