import { Box, Circle, ColorProps, Icon, useBoolean } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import EmojiPicker, { Emoji, EmojiClickData, SkinTones } from 'emoji-picker-react';
import { GLOBAL_EMOJI_TYPE } from '../../../../types/Constants.ts';
import isEmpty from 'lodash/isEmpty';
import { LuSmilePlus } from 'react-icons/lu';

interface Props {
	color?: ColorProps['color'];
	value: EmojiClickData['unified'];
	error: boolean;
	onIconSelected: (emojiUnified: EmojiClickData['unified']) => void;
}

export const IconPicker: React.FC<Props> = ({ color, value, error, onIconSelected }) => {
	const [flag, setFlag] = useBoolean();
	const ref = useRef<HTMLDivElement>();

	const handleClickOutside = (event: MouseEvent) => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			setFlag.off();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleOnClick = (emoji: EmojiClickData) => {
		setFlag.off();
		onIconSelected(emoji.unified);
	};

	return (
		<Box ref={ref as React.LegacyRef<HTMLDivElement>}>
			{flag ? (
				<Box zIndex={1} color='white' position='absolute' left='50%' transform='translateX(-50%)' mt='4.7rem'>
					<EmojiPicker
						onEmojiClick={handleOnClick}
						autoFocusSearch={false}
						defaultSkinTone={SkinTones.MEDIUM_LIGHT}
						emojiStyle={GLOBAL_EMOJI_TYPE}
						width='100%'
						// reactionsDefaultOpen={true}
						skinTonesDisabled
					/>
				</Box>
			) : null}
			<Circle size={66} bgColor={color} color='black' onClick={setFlag.toggle} _hover={{ cursor: 'pointer' }} border='borderStroke' borderColor={!error ? 'brand' : 'red'}>
				{isEmpty(value) ? <Icon as={LuSmilePlus} boxSize='8' color={!error ? 'brand' : 'red'} /> : <Emoji unified={value} size={45} emojiStyle={GLOBAL_EMOJI_TYPE} />}
			</Circle>
		</Box>
	);
};

IconPicker.defaultProps = {
	color: 'white',
	value: '2795',
	error: false,
};
