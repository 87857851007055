import { useBreakpointValue, useToken } from '@chakra-ui/react';
import React, { useLayoutEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { WheelConfiguration } from '../../model/Visualization.ts';
import { useWheelData } from '../../hooks/useWheelData.ts';
import { insertNewlineEveryAmountWords } from '../../../../util/StringUtil.ts';
import { WheelEntry } from '../../service/VisualizationService.ts';
import { am5 } from '../../../commons/armcharts/AmChartsSetup.ts';
import { VisualizationChartWrapper } from '../VisualizationChartWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';

export const WheelVisualization: React.FC<VisualizationProps> = ({ visualization, presentationId }) => {
	const conf = visualization.configuration as WheelConfiguration;
	const [brand] = useToken('colors', ['brand']);
	const wheelInnerRadius = useBreakpointValue(
		{
			base: 8,
			md: 100,
			lg: 100,
		},
		{ ssr: false }
	);
	const { data, isLoading } = useWheelData(presentationId, visualization.id);

	// const hasData = !isLoading && !isEmpty(data);
	const grouped = groupBy(data, 'key');
	const wheelData = map(grouped, (items, key) => {
		const color = find(data, { key })?.color || brand;
		const entries = Array.from(new Set(flatMap(items, 'entries'))); //Remove duplicates
		return {
			name: key,
			truncatedName: truncate(key, {
				length: calculateTruncatedLengthParent(data),
			}),
			textWithLineBreaks: insertNewlineEveryAmountWords(key),
			nodeSettings: { fill: am5.color(color) },
			children: entries.map((entry) => ({
				name: entry,
				truncatedName: truncate(entry, {
					length: calculateTruncatedLengthChild(data),
				}),
				textWithLineBreaks: insertNewlineEveryAmountWords(entry),
				nodeSettings: { fill: am5.color(color) },
				value: 1,
			})),
		};
	});

	useLayoutEffect(() => {
		const root = am5.Root.new('chartdiv');

		if (root && !isEmpty(wheelData)) {
			//https://www.amcharts.com/docs/v5/concepts/responsive/
			const responsiveTheme = am5themes_Responsive.new(root);
			responsiveTheme.addRule({
				// name: 'PictorialStackedSeries',
				relevant: function (width) {
					return width < am5themes_Responsive.XL;
				},
				applying: function () {
					series.labels.template.setAll({
						oversizedBehavior: 'truncate',
					});
				},
			});

			root.setThemes([am5themes_Animated.new(root), responsiveTheme]);

			const chart = root.container.children.push(
				am5.Container.new(root, {
					width: am5.percent(100),
					height: am5.percent(100),
				})
			);

			// Add title
			if (wheelInnerRadius === 100) {
				chart.children.push(
					am5.Label.new(root, {
						text: calculateChartTitle(conf.chartTitle),
						textAlign: 'center',
						breakWords: true,
						x: am5.p50,
						y: am5.p50,
						centerX: am5.p50,
						centerY: am5.p50,
						fontSize: 25,
						fontWeight: '500',
						fill: am5.color(0x385d63),
					})
				);
			}

			// Create series
			// https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
			const series = chart.children.push(
				am5hierarchy.Sunburst.new(root, {
					singleBranchOnly: true,
					downDepth: 1,
					initialDepth: 1,
					topDepth: 1,
					innerRadius: wheelInnerRadius,
					radius: am5.percent(95),
					valueField: 'value',
					categoryField: 'name',
					childDataField: 'children',
				})
			);

			series.nodes.template.setAll({
				tooltipText: '{textWithLineBreaks}',
			});

			series.slices.template.setAll({
				templateField: 'nodeSettings',
			});

			series.labels.template.setAll({
				text: '{name}',
				oversizedBehavior: 'truncate',
				breakWords: true,
			});

			series.getTooltip()?.label.setAll({
				oversizedBehavior: 'wrap',
			});
			series.getTooltip()?.label.adapters.add('maxWidth', function () {
				return chart.width() - 50;
			});

			// series.slices.template.events.on('click', function (ev) {
			// 	ev.target.adapters.add('fill', (_, target) => {
			// 		return am5.color('#00ff00');
			// 	});
			// 	console.log('Clicked on a column', ev.target.dataItem?.dataContext);
			// });

			// series.labels.template.adapters.add('textType', (_, target) => {
			// 	// @ts-expect-error Type error
			// 	return target.dataItem && target.dataItem.get('depth') > 1 ? 'circular' : 'radial';
			// });

			series.on('selectedDataItem', (selectedDataItem) => {
				if (selectedDataItem) {
					if (selectedDataItem.get('depth') > 0) {
						// series.set('downDepth', 1);
						series.labels.template.set('textType', 'circular');
					} else {
						series.labels.template.set('textType', 'radial');
						// series.set('downDepth', 0);
					}
				}
			});

			const parentData = [
				{
					children: [...wheelData],
				},
			];

			series.data.setAll(parentData);
			series.set('selectedDataItem', series.dataItems[0]);
			// Make stuff animate on load
			series.appear(1000, 100);
		}
		return () => {
			// Clean up on unmount
			root.dispose();
		};
	}, [wheelData]);

	return <VisualizationChartWrapper title={visualization.title} description={visualization.description} isLoading={isLoading} isNoData={isEmpty(data)} />;
};

function calculateTruncatedLengthParent(items?: WheelEntry[]) {
	const itemCount = items?.length || 0;
	if (itemCount <= 3) return 100;
	else if (itemCount <= 5) return 64;
	else if (itemCount <= 10) return 24;
	else if (itemCount <= 15) return 20;
	else return 10;
}

function calculateTruncatedLengthChild(items?: WheelEntry[]) {
	const itemCount = items?.reduce((sum, obj) => sum + obj.entries.length, 0) || 0;
	if (itemCount <= 3) return 160;
	else if (itemCount <= 5) return 90;
	else if (itemCount <= 10) return 64;
	else if (itemCount <= 20) return 34;
	else if (itemCount <= 30) return 24;
	else if (itemCount <= 40) return 20;
	else return 10;
}

function calculateChartTitle(chartTitle?: string) {
	if (!chartTitle) {
		return '';
	}
	return chartTitle.length > 9 ? insertNewlineEveryAmountWords(chartTitle, 1) : insertNewlineEveryAmountWords(chartTitle, 2);
}
