import ApiClient from '../../../apiClient';

//-------

export const saveMultipleChoiceEntry = async (presentationId: string, interactionId: string, answer: string[]): Promise<void> => {
	await ApiClient.getInstance().post(`/client-app/presentations/${presentationId}/interactions/${interactionId}/entries/multiple-choice`, { data: answer });
};

//-------

export const saveOpenEndedEntry = async (presentationId: string, interactionId: string, answer: string[]): Promise<void> => {
	await ApiClient.getInstance().post(`/client-app/presentations/${presentationId}/interactions/${interactionId}/entries/open-ended`, { data: answer });
};

//-------

export const saveOpenEndedLongEntry = async (presentationId: string, interactionId: string, answer: string[]): Promise<void> => {
	await ApiClient.getInstance().post(`/client-app/presentations/${presentationId}/interactions/${interactionId}/entries/open-ended-long`, { data: answer });
};

//-------

export const saveNumberEntry = async (presentationId: string, interactionId: string, entry: number): Promise<void> => {
	await ApiClient.getInstance().post(`/client-app/presentations/${presentationId}/interactions/${interactionId}/entries/number`, { data: entry });
};

//-------

export interface IconicEntry {
	icon: string;
	heading: string;
	text: string;
}

export const saveIconicEntry = async (presentationId: string, interactionId: string, entry: IconicEntry) => {
	await ApiClient.getInstance().post(`/client-app/presentations/${presentationId}/interactions/${interactionId}/entries/iconic`, { data: entry });
};

//-------

export interface DrawingEntry {
	drawing: string;
	heading: string;
	text: string;
}

export const saveDrawingEntry = async (presentationId: string, interactionId: string, entry: DrawingEntry) => {
	await ApiClient.getInstance().post(`/client-app/presentations/${presentationId}/interactions/${interactionId}/entries/drawing`, { data: entry });
};

//-------

export interface CategorisedEntry {
	key: string;
	entries: string[];
}

export const saveCategorisedEntry = async (presentationId: string, interactionId: string, entry: CategorisedEntry[]) => {
	await ApiClient.getInstance().post(`/client-app/presentations/${presentationId}/interactions/${interactionId}/entries/categorised`, { data: entry });
};

//-------

export interface ValuationEntry {
	key: string;
	entry: number;
}

export const saveValuationEntry = async (presentationId: string, interactionId: string, entries: ValuationEntry[]) => {
	await ApiClient.getInstance().post(`/client-app/presentations/${presentationId}/interactions/${interactionId}/entries/valuation`, { data: entries });
};

//---------

export interface ImageEntry {
	imageUrl: string;
	heading: string;
	text: string;
}

export const saveImageEntry = async (presentationId: string, interactionId: string, entry: ImageEntry) => {
	await ApiClient.getInstance().post(`/client-app/presentations/${presentationId}/interactions/${interactionId}/entries/image`, { data: entry });
};

//---------

export interface LinkEntry {
	heading: string;
	text: string;
	link: string;
	imageUrl: string;
}

export const saveLinkEntry = async (presentationId: string, interactionId: string, entry: LinkEntry) => {
	await ApiClient.getInstance().post(`/client-app/presentations/${presentationId}/interactions/${interactionId}/entries/link`, { data: entry });
};
