import React, { useEffect } from 'react';
import { CategorisedConfigurationCategoryEntry, CategorisedInteractionConfigurationT, InteractionT } from '../../../../model/Interaction.ts';
import { Control, useFieldArray, UseFormRegister, UseFormWatch } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { Flex, Icon, IconButton, Input, InputGroup, InputRightElement, VStack } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import { FiPlus } from 'react-icons/fi';
import { Entry } from '../../CategorisedInteraction.tsx';
import { validateMaxLength, validateMinLength } from '../../../../validation/InteractionValidation.ts';

interface Props {
	interaction: InteractionT;
	categoryEntry: CategorisedConfigurationCategoryEntry;
	register: UseFormRegister<Entry>;
	watch: UseFormWatch<Entry>;
	control: Control<Entry>;
}

export const CategorisedCategory: React.FC<Props> = ({ interaction, categoryEntry, register, watch, control }) => {
	const categorisedConfig = interaction.configuration as CategorisedInteractionConfigurationT;

	const { fields, append, remove } = useFieldArray({
		name: `entry.[${categoryEntry.id}]`,
		control,
		rules: {
			minLength: 1,
			maxLength: categorisedConfig.maximumAnswers,
			required: interaction.required,
		},
	});

	const canAdd = fields.length < categorisedConfig.maximumAnswers;
	const canRemove = fields.length > 1;

	useEffect(() => {
		if (isEmpty(fields)) {
			append({ value: '' }, { shouldFocus: false });
		}
	}, [append, fields]);

	const handleAdd = () => {
		if (canAdd) {
			append(
				{ value: '' },
				{
					focusName: `entry.[${categoryEntry.id}].${fields.length}.value`,
					shouldFocus: false,
				}
			);
		}
	};

	const handleRemove = (index: number) => () => {
		if (canRemove) {
			remove(index);
		}
	};

	return (
		<>
			<VStack my={4}>
				{fields.map((field, index) => (
					<InputGroup key={field.id} flexDirection='column' width='100%'>
						<Input
							mt={2}
							id={field.id}
							variant='outline'
							autoComplete='off'
							autoFocus={false}
							{...register(`entry.[${categoryEntry.id}].${index}.value` as const, {
								onChange: () => {
									const emptyField = watch(`entry.[${categoryEntry.id}]`).filter((value) => value.value === '').length > 0;
									if (!emptyField && canAdd) {
										handleAdd();
									}
								},
								validate: {
									minLength: (value) => {
										return validateMinLength(value);
									},
									maxLength: (value) => {
										return validateMaxLength(value, 500);
									},
								},
							})}
						/>
						<InputRightElement px={6} pt={19} onClick={handleRemove(index)} display={index !== 0 ? 'flex' : 'none'}>
							<IconButton rounded='50' aria-label='Delete' size='xs' icon={<Icon color='brand' as={MdClose} />} />
						</InputRightElement>
					</InputGroup>
				))}
			</VStack>
			{canAdd && (
				<Flex px={3} py={0} my={0} justifyContent='flex-end'>
					<IconButton mt={4} fontWeight='bold' rounded='50' bg={categoryEntry.color} aria-label='Add' onClick={handleAdd} size='md' icon={<Icon as={FiPlus} />} />
				</Flex>
			)}
		</>
	);
};

CategorisedCategory.defaultProps = {};
