import React, { forwardRef } from 'react';
import { Box, Circle, createIcon, Flex, Icon, Text, useStyleConfig } from '@chakra-ui/react';

type RadioProps = {
	option: string;
	currentOption: string;
} & React.InputHTMLAttributes<HTMLInputElement>; // Extend standard input attributes

export const RadioCard = forwardRef<HTMLInputElement, RadioProps>(({ option, currentOption, ...props }, ref) => {
	const styles = useStyleConfig('RadioCard');

	return (
		<Flex as='label' sx={styles} cursor='pointer' width='100%' gap={2}>
			<Box flex='1'>
				<Text fontWeight='medium' fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }} justifySelf='center' wordBreak='break-word'>
					{option}
				</Text>
			</Box>
			<input type='radio' {...props} ref={ref} value={option} hidden />
			<Flex alignItems='center'>
				{option === currentOption ? (
					<Circle bg='brand' size='8'>
						<Icon as={CheckIcon} boxSize='3.5' color='white' />
					</Circle>
				) : (
					<Circle bg='white' size='8' border='borderStroke' borderColor='brand' />
				)}
			</Flex>
		</Flex>
	);
});

export const CheckIcon = createIcon({
	displayName: 'CheckIcon',
	viewBox: '0 0 12 10',
	path: <polyline fill='none' strokeWidth='2px' stroke='currentColor' strokeDasharray='16px' points='1.5 6 4.5 9 10.5 1' />,
});

RadioCard.displayName = 'RadioCardInput'; // It's good practice to set a displayName when using forwardRef.
