import React from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useSliderControls } from '../SliderControlsProvider/SliderControlsProvider.tsx';

interface Props {
	onGoForward?: () => void; //Override default goForward behaviour
}

export const SlideArrows: React.FC<Props> = ({ onGoForward }) => {
	const slider = useSliderControls();

	const handleOnGoForward = () => {
		if (onGoForward) {
			onGoForward();
		} else {
			slider.goForward();
		}
	};
	return (
		<>
			{/* Navigation Arrows */}
			{!slider.presentation.hideControls ? (
				<>
					<Box position='absolute' left={0} top='50%' transform='translateY(-50%)' padding='0 10px' cursor={slider.canGoBack ? 'pointer' : 'default'} onClick={slider.goBack}>
						{slider.canGoBack && <Icon fontSize={40} padding={2} as={BsArrowLeft} color='white' borderRadius='full' backgroundColor='brand' />}
					</Box>
					<Box
						position='absolute'
						right={0}
						top='50%'
						transform='translateY(-50%)'
						padding='0 10px'
						cursor={slider.canGoForward ? 'pointer' : 'default'}
						onClick={handleOnGoForward}
					>
						{slider.canGoForward && <Icon fontSize={40} padding={2} as={BsArrowRight} color='white' borderRadius='full' backgroundColor='brand' />}
					</Box>
				</>
			) : null}
		</>
	);
};
