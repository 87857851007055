import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import './styles.css';
import { StaticContentProps } from '../StaticContent/StaticContent.tsx';

export const StaticContentHtml: React.FC<StaticContentProps> = ({ staticContent }) => {
	return (
		<Flex as='section' flexDirection='column' height='100%' mx={[null, null, 16]}>
			<Box my={16} mx={4} overflowY='auto' maxHeight='70%' height='70%' className='html-box' dangerouslySetInnerHTML={{ __html: staticContent.content }} />
		</Flex>
	);
};
